import { Badge } from 'components/atoms/Badge'
import styled from 'styled-components'
import { Text, TextColor, TextVariant } from 'components/atoms/Text'
import { mapVerificationStatusToText } from './mapVerificationStatusToText'
import { IdentityVerificationStatus } from 'types/api'
import { LoaderIcon } from 'components/atoms/Icons'

interface Props {
  status: IdentityVerificationStatus | 'Soon'
  loading?: boolean
  label?: string
  showBadge?: boolean
  variant?: TextVariant
  bold?: boolean
  labelColor?: TextColor
}

export function VerificationStatus({
  status,
  label,
  loading,
  showBadge = true,
  variant = 'body1',
  bold = true,
  labelColor = 'dark',
}: Props) {
  return (
    <VerificationStatusContainer>
      <Text variant={variant} color={labelColor} bold={bold}>
        {label ?? 'Verification:'}
      </Text>
      {!loading && showBadge && <Badge>{mapVerificationStatusToText(status)}</Badge>}
      {loading && (
        <LoadingBadge>
          <LoaderIcon size={16} /> Loading...
        </LoadingBadge>
      )}
    </VerificationStatusContainer>
  )
}

export const VerificationStatusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LoadingBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.Text02};
  font-size: 12px;
`
