import { Icon } from 'components/atoms/Icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Row } from 'components/atoms/Row'
import { Text } from 'components/atoms/Text'
import { BorderRadiuses } from 'styles'
import { BaseBadge } from 'components/atoms/Badge'

export interface NavLinkProps {
  href: string
  additionalHrefs?: string[]
  title: string
  icon: string
  notifications?: number | null
}

export const NavLink = ({ href, additionalHrefs, title, icon, notifications }: NavLinkProps) => {
  const router = useRouter()

  const isActivePage = router.pathname === href || additionalHrefs?.includes(router.pathname)

  return (
    <Link href={href} passHref legacyBehavior>
      <NavigationLink className={isActivePage ? 'active' : ''}>
        <NavigationLinkContainer>
          <Icon>{icon}</Icon>
          <LinkText color="default" bold>
            {title}
          </LinkText>
          {!!notifications && <NotificationBadge>{notifications}</NotificationBadge>}
        </NavigationLinkContainer>
      </NavigationLink>
    </Link>
  )
}

const NavigationLink = styled.a`
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.Corduroy};

  &:hover {
    background-color: ${({ theme }) => theme.colors.GrayNurse};
  }

  &.active {
    border-right: 4px solid ${({ theme }) => theme.colors.HeavyMetal};
    background-color: ${({ theme }) => theme.colors.GrayNurse};
    color: ${({ theme }) => theme.colors.HeavyMetal};

    @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
      border-right: none;
      border-left: 4px solid ${({ theme }) => theme.colors.HeavyMetal};
    }
  }
`

const NavigationLinkContainer = styled(Row)`
  padding: 16px 40px;
  column-gap: 8px;
`
const LinkText = styled(Text)`
  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    display: none;
  }

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    display: revert;
  }
`

const NotificationBadge = styled(BaseBadge)`
  border-radius: ${BorderRadiuses.l};
  background-color: ${({ theme }) => theme.colors.CopperOrange};
  color: ${({ theme }) => theme.colors.White};
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  padding: 1px 4px;

  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    position: absolute;
    left: 50px;
  }
  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    position: revert;
  }
`
