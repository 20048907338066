import styled from 'styled-components'
import { zIndexes } from 'styles'
import { CloseIcon } from 'components/atoms/Icons'
import { Button } from 'components/atoms/Button'
import { Navigation } from './Navigation'
import { AccountSection } from './AccountSection'
import { SignInSection } from './SignInSection'
import { Column } from 'components/atoms/Column'

interface Props {
  setMenuOpen: (val: boolean) => void
}

export const MobileMenu = ({ setMenuOpen }: Props) => {
  return (
    <Overlay>
      <MenuWrapper>
        <MenuHeader>
          <CloseButtonContainer>
            <Button size="small" icon={<CloseIcon size={24} />} narrow onClick={() => setMenuOpen(false)} />
          </CloseButtonContainer>
          <Navigation />
        </MenuHeader>
        <ButtonsContainer>
          <SignInSection />
          <AccountSection />
        </ButtonsContainer>
      </MenuWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.ModalOverlay};
  z-index: ${zIndexes.modalBackground};
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  width: 260px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.White};
  z-index: ${zIndexes.modalContent};
`
const MenuHeader = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`
const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 65px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Mouse};
`

const ButtonsContainer = styled(Column)`
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.colors.Mouse};
`
