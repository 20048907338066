import { ChainId } from '@usedapp/core'
import { Subgraph, SUBGRAPH_URLS } from 'backend/constants'
import { SupportedChainId } from 'constants/chain'

export function getQueryDataSource(chainId: SupportedChainId = ChainId.Mainnet): {
  endpoint: string
  fetchParams?: RequestInit
} {
  return {
    endpoint: SUBGRAPH_URLS[Subgraph.Structured][chainId],
    fetchParams: { headers: { ['Content-Type']: 'application/json' } },
  }
}
