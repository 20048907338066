import { useUser } from '@supabase/auth-helpers-react'
import { useEthers } from '@usedapp/core'
import { UserAvatar, Username, UserSection } from 'components/Login/UserAvatar'
import { UserModal } from 'components/organisms/Modals/UserModal'

export const MobileSignInSection = () => {
  const user = useUser()
  const { account } = useEthers()

  if (!user || account) return null

  return (
    <UserSection>
      <UserModal trigger={<UserAvatar />} />
      <Username bold>{user.email}</Username>
    </UserSection>
  )
}
