import { CheckCircleIcon } from 'components/atoms/Icons'
import styled from 'styled-components'

export const ConnectedText = () => (
  <Container>
    <CheckCircleIcon />
    Wallet Connected
  </Container>
)

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
