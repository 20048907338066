import Head from 'next/head'
import { ReactNode, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Column } from 'components/atoms/Column'
import { Sidebar } from './Sidebar'
import { LayoutContext } from 'providers/LayoutProvider'

export interface LayoutProps {
  children: ReactNode
  className?: string
  hideNavigation?: boolean
  centered?: boolean
  hideAccountSection?: boolean
}

export const Layout = ({
  children,
  hideNavigation = false,
  centered = false,
  hideAccountSection = false,
  className,
}: LayoutProps) => {
  const mainRef = useRef<HTMLElement>(null)

  return (
    <LayoutContext.Provider value={{ mainRef }}>
      <Head>
        <title>Archblock</title>
      </Head>
      <LayoutContainer className={className}>
        <Sidebar hideNavigation={hideNavigation} hideAccountSection={hideAccountSection} />
        <MainContainer ref={mainRef} centered={centered}>
          {children}
        </MainContainer>
      </LayoutContainer>
    </LayoutContext.Provider>
  )
}

const LayoutContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    flex-direction: column;
  }
`

export const MainContainer = styled.main<Pick<LayoutProps, 'centered'>>`
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 0 80px 16px;
  margin: 16px 0 48px;
  background: ${({ theme }) => theme.colors.White};
  overflow: auto;
  gap: 32px;

  @media ${({ theme }) => theme.queries.desktopAndSmaller} {
    padding: 0 40px;
    margin-right: auto;
  }

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    padding: 0 24px;
    margin: 0 0 32px;
  }

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    padding: 0 16px;
  }

  ${({ centered }) =>
    centered &&
    css`
      padding: 0 ${({ theme }) => theme.sidebarWidth.default}px 16px 0;

      @media ${({ theme }) => theme.queries.desktopAndSmaller} {
        padding: 0 ${({ theme }) => theme.sidebarWidth.desktopAndSmaller}px 16px 0;
      }

      @media ${({ theme }) => theme.queries.laptopAndSmaller} {
        padding: 0 ${({ theme }) => theme.sidebarWidth.laptopAndSmaller}px 16px 0;
      }

      @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
        padding: 0 16px;
      }
    `}
`
export const Content = styled(Column)<Pick<LayoutProps, 'centered'>>`
  flex: 1;
  gap: 32px;
  width: 100%;
  max-width: ${({ centered }) => (centered ? '920px' : '100%')};
  margin: 0 auto;
  padding: 0 40px;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    padding: 0;
  }
`
