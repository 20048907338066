import { Logo } from 'components/atoms/Logo'
import {
  ButtonsContainer,
  Header,
  LogoContainer,
  MobileNavigation,
  SidebarContainer,
  SidebarProps,
} from 'components/Layout'
import { MobileAccountSection } from './MobileAccountSection'
import Link from 'next/link'
import { SignInButton } from './SignInSection'
import { Button } from 'components/atoms/Button'
import { AccountModal } from 'components/organisms/Modals'
import { useEthers } from '@usedapp/core'
import { useUser } from '@supabase/auth-helpers-react'
import { MobileSignInSection } from './MobileSignInSection'

export const MobileSidebar = ({ hideNavigation, hideAccountSection }: SidebarProps) => {
  const { account } = useEthers()
  const user = useUser()

  return (
    <SidebarContainer hideNavigation={hideNavigation}>
      <Header>
        <Link href="/" passHref legacyBehavior>
          <LogoContainer>
            <Logo full />
          </LogoContainer>
        </Link>
        {!hideAccountSection && (
          <>
            <MobileAccountSection />
            <MobileSignInSection />
          </>
        )}
        {!hideNavigation && <MobileNavigation />}
      </Header>
      <ButtonsContainer hideAccountSection={(!!user && !!account) || hideAccountSection}>
        {!user && (
          <SignInButton view="primary" href="/signin" wide>
            Sign in
          </SignInButton>
        )}
        {!account && (
          <AccountModal
            trigger={
              <Button view="secondary" wide>
                Connect wallet
              </Button>
            }
          />
        )}
      </ButtonsContainer>
    </SidebarContainer>
  )
}
