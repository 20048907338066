import styled from 'styled-components'
import Image from 'next/image'
import logoColored from '/public/images/logo-color.png'

export interface LogoTextIconProps {
  colored?: boolean
}

export const LogoTextIcon = ({ colored = false }: LogoTextIconProps) => {
  if (colored) {
    return <Image src={logoColored} alt="Archblock logo" width={198} height={31} />
  }

  return (
    <LogoTextIconSvg width="110" height="20" viewBox="0 0 110 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7583 0.5H0V9.32353C2.10112 6.8577 5.3697 5.34461 8.7754 5.30562C12.2454 5.26589 15.6084 6.76528 17.7583 9.27027V0.5ZM17.7583 14.8669C17.4253 9.86736 13.7076 6.35908 8.78816 6.41542C3.87355 6.4717 0.238244 10.0649 0 15.0638V16.8842L0.0258883 19.4617H17.7583V14.8669Z"
        fill="currentColor"
      />
      <path
        d="M27.3121 6.97461C25.3057 6.97461 23.9964 8.08211 23.7413 9.84325H24.9996C25.2207 8.80837 25.8668 8.04582 27.3121 8.04582C28.6894 8.04582 29.3186 8.55416 29.3186 9.9885V10.9145C24.4725 12.0946 23.6053 13.8843 23.6223 16.4261H24.8126C24.8126 14.665 25.3567 13.2203 29.3186 12.0765V16.4261H30.5088V9.89773C30.5088 7.77346 29.0125 6.97461 27.3121 6.97461Z"
        fill="currentColor"
      />
      <path
        d="M40.0139 8.42784V7.15691C37.1232 6.52146 35.8872 8.01407 35.2805 8.91018L35.3487 7.15691H34.1582L34.1752 16.4269H35.3657L35.3487 11.8956C35.3487 10.189 36.5791 7.64711 40.0139 8.42784Z"
        fill="currentColor"
      />
      <path
        d="M46.2553 16.4158C48.3636 16.4158 49.894 15.0904 50.166 13.2203H48.9247C48.6697 14.346 47.8195 15.2175 46.2553 15.2175C44.3679 15.2175 43.1775 13.7105 43.1775 11.6952C43.1775 9.6617 44.3509 8.17289 46.2553 8.17289C47.7857 8.17289 48.6527 9.02625 48.9077 10.1882H50.1319C49.8091 8.33629 48.3636 6.97461 46.2553 6.97461C43.7386 6.97461 41.9873 9.02625 41.9873 11.6952C41.9873 14.3641 43.7557 16.4158 46.2553 16.4158Z"
        fill="currentColor"
      />
      <path
        d="M53.1699 16.4265H54.3601V8.33671H56.4111C57.983 8.33671 59.2571 9.69734 59.2571 11.3757V16.4265H60.4476V11.3644C60.4476 9.04049 58.6832 7.15658 56.5068 7.15658H54.3601V3.57715H53.1699V16.4265Z"
        fill="currentColor"
      />
      <path
        d="M68.1705 16.4162C70.6702 16.4162 72.3536 14.3646 72.3536 11.6956C72.3536 9.00849 70.6702 6.97503 68.1705 6.97503C66.7254 6.97503 65.8069 7.62863 65.1609 8.53644V3.57715H63.9707V16.4265H65.1609V14.8548C65.8921 15.817 66.7083 16.4162 68.1705 16.4162ZM68.1705 15.2179C66.2661 15.2179 65.1609 13.7291 65.1609 11.6956C65.1609 9.57135 66.3343 8.17331 68.1705 8.17331C70.0749 8.17331 71.1461 9.73475 71.1461 11.6956C71.1461 13.7109 70.0579 15.2179 68.1705 15.2179Z"
        fill="currentColor"
      />
      <path d="M75.3916 16.4265H76.5818V3.57715H75.3916V16.4265Z" fill="currentColor" />
      <path
        d="M84.1704 6.97461C81.6707 6.97461 79.8516 9.02625 79.8516 11.6952C79.8516 14.3641 81.654 16.4158 84.1704 16.4158C86.67 16.4158 88.4554 14.3641 88.4554 11.6952C88.4554 9.00807 86.67 6.97461 84.1704 6.97461ZM84.1704 8.15474C86.0748 8.15474 87.2649 9.71618 87.2649 11.6952C87.2649 13.7287 86.0577 15.2356 84.1704 15.2356C82.266 15.2356 81.0247 13.7468 81.0247 11.6952C81.0247 9.6617 82.283 8.15474 84.1704 8.15474Z"
        fill="currentColor"
      />
      <path
        d="M95.353 16.4158C97.4616 16.4158 98.9916 15.0904 99.264 13.2203H98.0227C97.7674 14.346 96.9174 15.2175 95.353 15.2175C93.4656 15.2175 92.2754 13.7105 92.2754 11.6952C92.2754 9.6617 93.4486 8.17289 95.353 8.17289C96.8834 8.17289 97.7506 9.02625 98.0057 10.1882H99.2299C98.9067 8.33629 97.4616 6.97461 95.353 6.97461C92.8363 6.97461 91.085 9.02625 91.085 11.6952C91.085 14.3641 92.8533 16.4158 95.353 16.4158Z"
        fill="currentColor"
      />
      <path
        d="M103.689 3.57715H102.498V16.4265H103.689V12.7305L105.066 11.3506L108.603 16.4265H109.98L105.899 10.5699L109.402 7.15658H107.769L103.689 11.2599V3.57715Z"
        fill="currentColor"
      />
    </LogoTextIconSvg>
  )
}

export const LogoTextIconSvg = styled.svg`
  width: 158px;
  height: 25px;

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    width: 110px;
    height: 20px;
  }
`
