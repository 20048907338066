import { AccountModal } from 'components/organisms/Modals'
import styled from 'styled-components'
import { Button, ButtonProps } from 'components/atoms/Button'
import { Text } from 'components/atoms/Text'
import { shortAddress } from 'utils'
import { ConnectedText } from './ConnectedText'
import { useEthers } from '@usedapp/core'
import { Row } from 'components/atoms/Row'

interface Props extends Pick<ButtonProps, 'loading' | 'view' | 'disabled'> {
  text?: string
  withDisconnectButton?: boolean
  className?: string
}

export function ConnectWalletButton({
  text,
  loading,
  disabled,
  withDisconnectButton,
  view = 'secondary',
  className,
}: Props) {
  const { deactivate, account } = useEthers()
  const showDisconnectButton = withDisconnectButton && account

  return (
    <div>
      <AccountModal
        trigger={
          <ConnectButton disabled={disabled} view={view} loading={loading} className={className}>
            {showDisconnectButton ? (
              <ConnectedText />
            ) : (
              text ?? (
                <p>
                  Connect<ConnectText> wallet</ConnectText>
                </p>
              )
            )}
          </ConnectButton>
        }
      />
      {showDisconnectButton && (
        <DisconnectSection>
          <Text color="light" variant="body3">
            {shortAddress(account)}
          </Text>
          <DisconnectButton size="small" view="link" onClick={deactivate}>
            Disconnect
          </DisconnectButton>
        </DisconnectSection>
      )}
    </div>
  )
}

export const ConnectButton = styled(Button)`
  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    padding: 4px;
  }

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    padding: revert;
    width: 100%;
  }
`

const ConnectText = styled.span`
  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    display: none;
  }

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    display: revert;
  }
`

const DisconnectSection = styled(Row)`
  gap: 8px;
  margin-top: 8px;
`

const DisconnectButton = styled(Button)`
  color: ${({ theme }) => theme.colors.PersianRed};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 0;
  min-width: unset;
  height: 16px;
`
