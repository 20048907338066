import styled from 'styled-components'
import { useEthers } from '@usedapp/core'
import { Account, AccountName } from 'components/molecules/Account/Account'
import { AccountModal } from 'components/organisms/Modals'
import { ConnectButton, ConnectWalletButton } from 'components/molecules/ConnectWalletButton'
import { Wrapper } from 'components/molecules/Account'
import { VerificationStatusContainer } from 'components/molecules/VerificationStatus'

interface Props {
  laptopLayout?: boolean
}

export const AccountSection = ({ laptopLayout }: Props) => {
  const { account } = useEthers()

  return (
    <AccountContainer laptopLayout={laptopLayout}>
      {!account ? (
        <ConnectWalletButton />
      ) : (
        <>
          <AccountModal
            trigger={
              <Account
                address={account}
                shortenOptions={{
                  limitBefore: laptopLayout ? 4 : 8,
                }}
              />
            }
          />
        </>
      )}
    </AccountContainer>
  )
}

const AccountContainer = styled.footer<Props>`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;

  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    padding: 24px 8px;
    align-items: center;
  }

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    padding: 16px 24px;
  }

  ${Wrapper} {
    @media ${({ theme }) => theme.queries.laptopAndSmaller} {
      flex-direction: column;
    }

    @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
      flex-direction: revert;
    }
  }

  ${AccountName} {
    @media ${({ theme }) => theme.queries.laptopAndSmaller} {
      align-self: flex-start;
      width: 100%;
    }
  }

  ${VerificationStatusContainer} {
    @media ${({ theme }) => theme.queries.laptopAndSmaller} {
      flex-direction: column;
      gap: 8px;
    }

    @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
      flex-direction: revert;
    }
  }

  ${ConnectButton} {
    ${({ laptopLayout }) => (laptopLayout ? 'min-width: unset;' : 'min-width: 208px;')}
  }
`
