import { useEthers } from '@usedapp/core'
import { Account } from 'components/molecules/Account'
import { AccountModal } from 'components/organisms/Modals'
import styled from 'styled-components'

const ADDRESS_SHORTEN_OPTIONS = { limitBefore: 4, limitAfter: 4 }

export function MobileAccountSection() {
  const { account } = useEthers()

  if (!account) return null

  return (
    <MobileAccount>
      <AccountModal trigger={<Account shortenOptions={ADDRESS_SHORTEN_OPTIONS} address={account} />} />
    </MobileAccount>
  )
}

const MobileAccount = styled.div`
  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    max-width: 50%;
  }
`
