import styled from 'styled-components'
import { NavLink } from 'components/molecules/NavLink'
import { useEthers } from '@usedapp/core'
import { useIsManagerWhitelisted } from 'hooks'
import { isFeatureEnabled } from 'utils'
import { useUser } from '@supabase/auth-helpers-react'
import { useRouter } from 'next/router'
import { useCountReportingNotifications } from 'hooks/reporting/useCountReportingNotifications'

export const Navigation = () => {
  const {
    query: { userId },
  } = useRouter()
  const { account, chainId } = useEthers()
  const user = useUser()
  const { isWhitelisted } = useIsManagerWhitelisted(account, chainId)
  const isViewingOwnProfile = userId === user?.id
  const isInvestor = !isWhitelisted && account
  const { notificationsCount } = useCountReportingNotifications()
  const isReportingPageEnabled = isFeatureEnabled('reportingPage')

  return (
    <NavigationContainer>
      <NavLink
        href="/"
        additionalHrefs={[
          '/offering/[portfolioAddress]',
          `${!isViewingOwnProfile && '/profile/[userId]'}`,
          `${!user && '/signin'}`,
        ]}
        title="Offerings"
        icon="grid_view"
      />
      {isWhitelisted && <NavLink href="/dashboard" title="Dashboard" icon="space_dashboard" />}
      {isInvestor && user && <NavLink href="/my-investments" title="My investments" icon="space_dashboard" />}
      {isReportingPageEnabled && user && !isWhitelisted && (
        <NavLink href="/reporting" title="Reporting" icon="description" notifications={notificationsCount} />
      )}
      {isWhitelisted && user && (
        <NavLink
          href="/profile"
          additionalHrefs={isViewingOwnProfile ? [`/profile/[userId]`] : undefined}
          title="My profile"
          icon="account_box"
        />
      )}
    </NavigationContainer>
  )
}

const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`
