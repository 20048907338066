import { IconContainer, IconProps } from '../IconBase'
import { Img } from 'components/atoms/Image'
import { defaultImage } from 'constants/defaultImage'

export interface LogoIconProps extends IconProps {
  colored?: boolean
}

export const LogoIcon = ({ color, size = 25, className, colored = false }: LogoIconProps) => {
  if (colored) {
    return <Img image={defaultImage} size={size} alt="Archblock logo" variant="full" />
  }

  return (
    <IconContainer color={color} size={size} className={className}>
      <svg width={size} height={size} viewBox="0 0 21 21" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 0H0V9.77201C2.48468 7.04111 6.34993 5.36537 10.3773 5.3222C14.4807 5.27819 18.4576 6.93876 21 9.71301V0ZM21 15.9112C20.6063 10.3743 16.2099 6.48889 10.3924 6.55129C4.58066 6.61362 0.281734 10.5931 0 16.1293V18.1454L0.0306141 21H21V15.9112Z"
          fill="currentColor"
        />
      </svg>
    </IconContainer>
  )
}
