import { IdentityVerificationStatus } from 'types/api'

const titles: Record<IdentityVerificationStatus, string> = {
  InProgress: 'In progress',
  Verified: 'Verified',
  Unverified: 'Unverified',
  Unknown: 'Unknown',
  Failed: 'Failed',
}

export function mapVerificationStatusToText(status: IdentityVerificationStatus | 'Soon') {
  if (status === 'Soon') {
    return 'Soon'
  }

  return titles[status]
}
