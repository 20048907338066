import styled from 'styled-components'
import { Logo } from 'components/atoms/Logo'
import { Navigation } from 'components/Layout'
import { AccountSection } from './AccountSection'
import { useWidthBreakpoint } from 'hooks'
import Link from 'next/link'
import { SignInSection } from './SignInSection'
import { MobileSidebar } from './MobileSidebar'

export interface SidebarProps {
  hideNavigation?: boolean
  hideAccountSection?: boolean
}

export const Sidebar = ({ hideNavigation, hideAccountSection }: SidebarProps) => {
  const laptopLayout = useWidthBreakpoint('laptopAndSmaller')
  const mobileLayout = useWidthBreakpoint('largeMobileAndSmaller')

  if (mobileLayout) {
    return <MobileSidebar hideNavigation={hideNavigation} hideAccountSection={hideAccountSection} />
  }

  return (
    <SidebarContainer hideNavigation={hideNavigation}>
      <Header>
        <Link href="/" passHref legacyBehavior>
          <LogoContainer>
            <Logo full={!laptopLayout} />
          </LogoContainer>
        </Link>
        {!hideNavigation && <Navigation />}
      </Header>
      {!hideNavigation && (
        <ButtonsContainer>
          <SignInSection />
          <AccountSection laptopLayout={laptopLayout} />
        </ButtonsContainer>
      )}
    </SidebarContainer>
  )
}

export const SidebarContainer = styled.div<SidebarProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 260px;
  padding: 24px 0 0;
  background: ${({ theme }) => theme.colors.White};
  border-right: 1px solid ${({ theme, hideNavigation }) => (hideNavigation ? theme.colors.White : theme.colors.Mouse)};

  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    width: 100px;
  }

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    width: 100%;
    height: unset;
    padding: 0px;
    border-right: none;
    border-bottom: 1px solid
      ${({ theme, hideNavigation }) => (hideNavigation ? theme.colors.White : theme.colors.Mouse)};
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
  width: 100%;

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    flex-direction: row;
    padding: 16px;
    gap: 16px;
  }
`
export const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    margin: unset;
  }
`
export const ButtonsContainer = styled.div<Pick<SidebarProps, 'hideAccountSection'>>`
  display: ${({ hideAccountSection }) => (hideAccountSection ? 'none' : 'flex')};
  flex-direction: column;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.colors.Mouse};

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    padding: 16px;
  }
`
