import { LogoIcon, LogoTextIcon } from 'components/atoms/Icons'

import styled from 'styled-components'
import { Row } from 'components/atoms/Row'

export interface Props {
  full?: boolean
  colored?: boolean
  size?: number
  color?: 'light' | 'dark'
}

export const Logo = ({ full = true, colored, size, color }: Props) => {
  return (
    <LogoContainer color={color}>
      {full ? <LogoTextIcon colored={colored} /> : <LogoIcon size={size} color="inherit" colored={colored} />}
    </LogoContainer>
  )
}

const LogoContainer = styled(Row)<Pick<Props, 'color'>>`
  column-gap: 7px;
  color: ${({ color, theme }) => {
    switch (color) {
      case 'light':
        return theme.colors.White
      case 'dark':
      default:
        return theme.colors.HeavyMetal
    }
  }};
`
