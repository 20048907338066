import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react'
import { useQuery } from '@tanstack/react-query'
import type { Database } from 'integrations/supabase/generated.types'

export function useCountReportingNotifications() {
  const supabase = useSupabaseClient<Database>()
  const user = useUser()
  const { data, isLoading } = useQuery({
    queryFn: async () => supabase.from('count_user_new_reports').select('count').maybeSingle(),
    enabled: !!user,
    queryKey: countReportingNotificationsQueryKey,
  })
  return {
    notificationsCount: data?.data?.count,
    isLoading,
  }
}

export const countReportingNotificationsQueryKey = ['count_user_new_reports'] as const
