import { Button } from 'components/atoms/Button'
import { MenuIcon } from 'components/atoms/Icons'
import { useState } from 'react'
import { MobileMenu } from 'components/Layout'

export const MobileNavigation = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <Button size="small" icon={<MenuIcon size={32} />} narrow onClick={() => setMenuOpen(true)} />
      {menuOpen && <MobileMenu setMenuOpen={setMenuOpen} />}
    </>
  )
}
