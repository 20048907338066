import * as Types from './types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
export const TokenFieldsFragmentDoc = `
    fragment TokenFields on Token {
  id
  chainId
  symbol
  name
  decimals
}
    `;
export const StructuredAssetPortfolioFieldsFragmentDoc = `
    fragment StructuredAssetPortfolioFields on StructuredAssetPortfolio {
  outstandingAssets
  outstandingPrincipal
  latestReportId
  currentRoi
}
    `;
export const CommonCarbonStructuredPortfolioFieldsFragmentDoc = `
    fragment CommonCarbonStructuredPortfolioFields on StructuredPortfolio {
  __typename
  id
  name
  chainId
  status
  manager {
    id
  }
  startDate
  endDate
  creationDate
  duration
  startDeadline
  minimumSize
  totalAccruedInterest
  totalInterestDisbursed
  latestCheckpoint {
    trancheTotalSupplies
    trancheTotalAssets
    portfolioTotalAssets
    timestamp
  }
  asset {
    ...TokenFields
  }
  ... on StructuredAssetPortfolio {
    ...StructuredAssetPortfolioFields
  }
}
    ${TokenFieldsFragmentDoc}
${StructuredAssetPortfolioFieldsFragmentDoc}`;
export const TrancheFieldsFragmentDoc = `
    fragment TrancheFields on TrancheVault {
  id
  depositController
  withdrawController
  managerFeeRate
  redemptions {
    id
    withdrawType
    assets
  }
  minimumDepositController {
    minimumDeposit
    ceiling
  }
}
    `;
export const CarbonStructuredPortfolioFieldsFragmentDoc = `
    fragment CarbonStructuredPortfolioFields on StructuredPortfolio {
  ...CommonCarbonStructuredPortfolioFields
  tranches {
    ...TrancheFields
  }
}
    ${CommonCarbonStructuredPortfolioFieldsFragmentDoc}
${TrancheFieldsFragmentDoc}`;
export const CarbonInvestmentFieldsFragmentDoc = `
    fragment CarbonInvestmentFields on TrancheVaultAction {
  shares
  assets
  action
  trancheVault {
    id
    withdrawController
    portfolio {
      id
    }
  }
}
    `;
export const FixedInterestOnlyLoanFieldsFragmentDoc = `
    fragment FixedInterestOnlyLoanFields on FixedInterestOnlyLoan {
  id
  chainId
  periodDuration
  periodCount
  periodsRepaid
  periodicPayment
  principal
  recipient
  endDate
  status
  currentPeriodEndDate
  gracePeriod
  baseAnnualRateInBPS
}
    `;
export const MultiWithdrawalControllerRedemptionFieldsFragmentDoc = `
    fragment MultiWithdrawalControllerRedemptionFields on MultiWithdrawalControllerRedemption {
  assets
  shares
  fee
  withdrawType
  timestamp
  owner
  txHash
  trancheVault {
    id
    withdrawController
    portfolio {
      ...CommonCarbonStructuredPortfolioFields
      expectedEquityRateFrom
      expectedEquityRateTo
    }
  }
}
    ${CommonCarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetCarbonStructuredPortfoliosDocument = `
    query getCarbonStructuredPortfolios {
  structuredPortfolios(
    orderBy: startDate
    orderDirection: desc
    where: {status_in: [CapitalFormation, Live]}
  ) {
    ...CarbonStructuredPortfolioFields
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const useGetCarbonStructuredPortfoliosQuery = <
      TData = Types.GetCarbonStructuredPortfoliosQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: Types.GetCarbonStructuredPortfoliosQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonStructuredPortfoliosQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonStructuredPortfoliosQuery, TError, TData>(
      variables === undefined ? ['getCarbonStructuredPortfolios'] : ['getCarbonStructuredPortfolios', variables],
      fetcher<Types.GetCarbonStructuredPortfoliosQuery, Types.GetCarbonStructuredPortfoliosQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonStructuredPortfoliosDocument, variables),
      options
    );
export const GetCarbonStructuredPortfoliosByAddressesDocument = `
    query getCarbonStructuredPortfoliosByAddresses($addresses: [ID!]!) {
  structuredPortfolios(
    orderBy: startDate
    orderDirection: desc
    where: {status_in: [CapitalFormation, Live], id_in: $addresses}
  ) {
    ...CarbonStructuredPortfolioFields
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const useGetCarbonStructuredPortfoliosByAddressesQuery = <
      TData = Types.GetCarbonStructuredPortfoliosByAddressesQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetCarbonStructuredPortfoliosByAddressesQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonStructuredPortfoliosByAddressesQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonStructuredPortfoliosByAddressesQuery, TError, TData>(
      ['getCarbonStructuredPortfoliosByAddresses', variables],
      fetcher<Types.GetCarbonStructuredPortfoliosByAddressesQuery, Types.GetCarbonStructuredPortfoliosByAddressesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonStructuredPortfoliosByAddressesDocument, variables),
      options
    );
export const GetCarbonStructuredPortfolioDocument = `
    query getCarbonStructuredPortfolio($address: ID!) {
  structuredPortfolio(id: $address) {
    ...CarbonStructuredPortfolioFields
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const useGetCarbonStructuredPortfolioQuery = <
      TData = Types.GetCarbonStructuredPortfolioQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetCarbonStructuredPortfolioQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonStructuredPortfolioQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonStructuredPortfolioQuery, TError, TData>(
      ['getCarbonStructuredPortfolio', variables],
      fetcher<Types.GetCarbonStructuredPortfolioQuery, Types.GetCarbonStructuredPortfolioQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonStructuredPortfolioDocument, variables),
      options
    );
export const GetStructuredPortfoliosDocument = `
    query getStructuredPortfolios($addresses: [ID!]!) {
  structuredPortfolios(where: {id_in: $addresses}) {
    ...CarbonStructuredPortfolioFields
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const useGetStructuredPortfoliosQuery = <
      TData = Types.GetStructuredPortfoliosQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetStructuredPortfoliosQueryVariables,
      options?: UseQueryOptions<Types.GetStructuredPortfoliosQuery, TError, TData>
    ) =>
    useQuery<Types.GetStructuredPortfoliosQuery, TError, TData>(
      ['getStructuredPortfolios', variables],
      fetcher<Types.GetStructuredPortfoliosQuery, Types.GetStructuredPortfoliosQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetStructuredPortfoliosDocument, variables),
      options
    );
export const GetCarbonManagerPortfoliosDocument = `
    query getCarbonManagerPortfolios($address: ID!) {
  manager(id: $address) {
    portfolios {
      ...CarbonStructuredPortfolioFields
    }
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const useGetCarbonManagerPortfoliosQuery = <
      TData = Types.GetCarbonManagerPortfoliosQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetCarbonManagerPortfoliosQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonManagerPortfoliosQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonManagerPortfoliosQuery, TError, TData>(
      ['getCarbonManagerPortfolios', variables],
      fetcher<Types.GetCarbonManagerPortfoliosQuery, Types.GetCarbonManagerPortfoliosQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonManagerPortfoliosDocument, variables),
      options
    );
export const GetCarbonManagerDocument = `
    query getCarbonManager($address: ID!) {
  manager(id: $address) {
    id
    isWhitelisted
  }
}
    `;
export const useGetCarbonManagerQuery = <
      TData = Types.GetCarbonManagerQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetCarbonManagerQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonManagerQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonManagerQuery, TError, TData>(
      ['getCarbonManager', variables],
      fetcher<Types.GetCarbonManagerQuery, Types.GetCarbonManagerQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonManagerDocument, variables),
      options
    );
export const GetCarbonManagerStatisticsDocument = `
    query getCarbonManagerStatistics($address: ID!) {
  manager(id: $address) {
    raisedTotals {
      amount
      token {
        decimals
        id
      }
    }
    portfoliosLaunched
  }
}
    `;
export const useGetCarbonManagerStatisticsQuery = <
      TData = Types.GetCarbonManagerStatisticsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetCarbonManagerStatisticsQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonManagerStatisticsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonManagerStatisticsQuery, TError, TData>(
      ['getCarbonManagerStatistics', variables],
      fetcher<Types.GetCarbonManagerStatisticsQuery, Types.GetCarbonManagerStatisticsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonManagerStatisticsDocument, variables),
      options
    );
export const GetCarbonStructuredPortfolioDepositsDocument = `
    query getCarbonStructuredPortfolioDeposits($address: ID!) {
  structuredPortfolio(id: $address) {
    tranches {
      id
      trancheVaultActions(first: 1000, where: {action: Deposit}) {
        sender
        assets
        timestamp
        txHash
      }
    }
  }
}
    `;
export const useGetCarbonStructuredPortfolioDepositsQuery = <
      TData = Types.GetCarbonStructuredPortfolioDepositsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetCarbonStructuredPortfolioDepositsQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonStructuredPortfolioDepositsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonStructuredPortfolioDepositsQuery, TError, TData>(
      ['getCarbonStructuredPortfolioDeposits', variables],
      fetcher<Types.GetCarbonStructuredPortfolioDepositsQuery, Types.GetCarbonStructuredPortfolioDepositsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonStructuredPortfolioDepositsDocument, variables),
      options
    );
export const GetCarbonStructuredPortfolioActionsDocument = `
    query getCarbonStructuredPortfolioActions($address: ID!) {
  structuredPortfolio(id: $address) {
    tranches {
      id
      trancheVaultActions(first: 1000) {
        action
        sender
        assets
      }
      redemptions(first: 1000) {
        withdrawType
        assets
        shares
        owner
      }
    }
  }
}
    `;
export const useGetCarbonStructuredPortfolioActionsQuery = <
      TData = Types.GetCarbonStructuredPortfolioActionsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetCarbonStructuredPortfolioActionsQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonStructuredPortfolioActionsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonStructuredPortfolioActionsQuery, TError, TData>(
      ['getCarbonStructuredPortfolioActions', variables],
      fetcher<Types.GetCarbonStructuredPortfolioActionsQuery, Types.GetCarbonStructuredPortfolioActionsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonStructuredPortfolioActionsDocument, variables),
      options
    );
export const GetFixedInterestOnlyLoansDocument = `
    query getFixedInterestOnlyLoans($portfolioAddress: ID!) {
  fixedInterestOnlyLoans(where: {portfolio_: {id: $portfolioAddress}}) {
    ...FixedInterestOnlyLoanFields
  }
}
    ${FixedInterestOnlyLoanFieldsFragmentDoc}`;
export const useGetFixedInterestOnlyLoansQuery = <
      TData = Types.GetFixedInterestOnlyLoansQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetFixedInterestOnlyLoansQueryVariables,
      options?: UseQueryOptions<Types.GetFixedInterestOnlyLoansQuery, TError, TData>
    ) =>
    useQuery<Types.GetFixedInterestOnlyLoansQuery, TError, TData>(
      ['getFixedInterestOnlyLoans', variables],
      fetcher<Types.GetFixedInterestOnlyLoansQuery, Types.GetFixedInterestOnlyLoansQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetFixedInterestOnlyLoansDocument, variables),
      options
    );
export const GetFixedInterestOnlyLoanDocument = `
    query getFixedInterestOnlyLoan($id: ID!) {
  fixedInterestOnlyLoan(id: $id) {
    ...FixedInterestOnlyLoanFields
  }
}
    ${FixedInterestOnlyLoanFieldsFragmentDoc}`;
export const useGetFixedInterestOnlyLoanQuery = <
      TData = Types.GetFixedInterestOnlyLoanQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetFixedInterestOnlyLoanQueryVariables,
      options?: UseQueryOptions<Types.GetFixedInterestOnlyLoanQuery, TError, TData>
    ) =>
    useQuery<Types.GetFixedInterestOnlyLoanQuery, TError, TData>(
      ['getFixedInterestOnlyLoan', variables],
      fetcher<Types.GetFixedInterestOnlyLoanQuery, Types.GetFixedInterestOnlyLoanQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetFixedInterestOnlyLoanDocument, variables),
      options
    );
export const GetCarbonManagerLoansDocument = `
    query getCarbonManagerLoans($managerAddress: String!) {
  fixedInterestOnlyLoans(
    where: {portfolio_: {manager: $managerAddress}, status: Started}
  ) {
    portfolio {
      asset {
        id
        decimals
      }
    }
    principal
  }
}
    `;
export const useGetCarbonManagerLoansQuery = <
      TData = Types.GetCarbonManagerLoansQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetCarbonManagerLoansQueryVariables,
      options?: UseQueryOptions<Types.GetCarbonManagerLoansQuery, TError, TData>
    ) =>
    useQuery<Types.GetCarbonManagerLoansQuery, TError, TData>(
      ['getCarbonManagerLoans', variables],
      fetcher<Types.GetCarbonManagerLoansQuery, Types.GetCarbonManagerLoansQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCarbonManagerLoansDocument, variables),
      options
    );
export const GetStructuredInvestmentsDocument = `
    query getStructuredInvestments($investorAddress: String!, $trancheAddresses: [String!]!, $trancheIds: [ID!]!) {
  trancheVaultActions(
    where: {owner: $investorAddress, action: Deposit, trancheVault_in: $trancheAddresses}
  ) {
    ...CarbonInvestmentFields
  }
  multiWithdrawalControllerRedemptions(
    where: {owner: $investorAddress, trancheVault_in: $trancheAddresses}
  ) {
    ...MultiWithdrawalControllerRedemptionFields
  }
  trancheVaults(where: {id_in: $trancheIds}) {
    id
    withdrawController
    portfolio {
      ...CommonCarbonStructuredPortfolioFields
      expectedEquityRateFrom
      expectedEquityRateTo
    }
  }
}
    ${CarbonInvestmentFieldsFragmentDoc}
${MultiWithdrawalControllerRedemptionFieldsFragmentDoc}
${CommonCarbonStructuredPortfolioFieldsFragmentDoc}`;
export const useGetStructuredInvestmentsQuery = <
      TData = Types.GetStructuredInvestmentsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetStructuredInvestmentsQueryVariables,
      options?: UseQueryOptions<Types.GetStructuredInvestmentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetStructuredInvestmentsQuery, TError, TData>(
      ['getStructuredInvestments', variables],
      fetcher<Types.GetStructuredInvestmentsQuery, Types.GetStructuredInvestmentsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetStructuredInvestmentsDocument, variables),
      options
    );
export const GetStructuredTrancheInvestmentsDocument = `
    query getStructuredTrancheInvestments($investorAddress: String!, $trancheAddress: String!, $trancheId: ID!) {
  trancheVaultActions(
    where: {owner: $investorAddress, action: Deposit, trancheVault: $trancheAddress}
  ) {
    ...CarbonInvestmentFields
  }
  multiWithdrawalControllerRedemptions(
    where: {owner: $investorAddress, trancheVault: $trancheAddress}
  ) {
    ...MultiWithdrawalControllerRedemptionFields
  }
  trancheVaults(where: {id: $trancheId}) {
    id
    withdrawController
    portfolio {
      ...CommonCarbonStructuredPortfolioFields
      expectedEquityRateFrom
      expectedEquityRateTo
    }
  }
}
    ${CarbonInvestmentFieldsFragmentDoc}
${MultiWithdrawalControllerRedemptionFieldsFragmentDoc}
${CommonCarbonStructuredPortfolioFieldsFragmentDoc}`;
export const useGetStructuredTrancheInvestmentsQuery = <
      TData = Types.GetStructuredTrancheInvestmentsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetStructuredTrancheInvestmentsQueryVariables,
      options?: UseQueryOptions<Types.GetStructuredTrancheInvestmentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetStructuredTrancheInvestmentsQuery, TError, TData>(
      ['getStructuredTrancheInvestments', variables],
      fetcher<Types.GetStructuredTrancheInvestmentsQuery, Types.GetStructuredTrancheInvestmentsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetStructuredTrancheInvestmentsDocument, variables),
      options
    );
export const GetMultiWithdrawalControllerRedemptionsDocument = `
    query getMultiWithdrawalControllerRedemptions($trancheAddress: String!, $withdrawalType: MultiWithdrawalControllerRedemptionType!) {
  multiWithdrawalControllerRedemptions(
    where: {trancheVault: $trancheAddress, withdrawType: $withdrawalType}
  ) {
    ...MultiWithdrawalControllerRedemptionFields
  }
}
    ${MultiWithdrawalControllerRedemptionFieldsFragmentDoc}`;
export const useGetMultiWithdrawalControllerRedemptionsQuery = <
      TData = Types.GetMultiWithdrawalControllerRedemptionsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetMultiWithdrawalControllerRedemptionsQueryVariables,
      options?: UseQueryOptions<Types.GetMultiWithdrawalControllerRedemptionsQuery, TError, TData>
    ) =>
    useQuery<Types.GetMultiWithdrawalControllerRedemptionsQuery, TError, TData>(
      ['getMultiWithdrawalControllerRedemptions', variables],
      fetcher<Types.GetMultiWithdrawalControllerRedemptionsQuery, Types.GetMultiWithdrawalControllerRedemptionsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetMultiWithdrawalControllerRedemptionsDocument, variables),
      options
    );
export const GetLoansAndRepaymentsDocument = `
    query getLoansAndRepayments($portfolioAddress: ID!) {
  loanActions(where: {portfolio_: {id: $portfolioAddress}, action: LoanRepaid}) {
    action
    assets
    timestamp
    sender
    txHash
  }
  fixedInterestOnlyLoans(where: {portfolio_: {id: $portfolioAddress}}) {
    ...FixedInterestOnlyLoanFields
  }
}
    ${FixedInterestOnlyLoanFieldsFragmentDoc}`;
export const useGetLoansAndRepaymentsQuery = <
      TData = Types.GetLoansAndRepaymentsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetLoansAndRepaymentsQueryVariables,
      options?: UseQueryOptions<Types.GetLoansAndRepaymentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetLoansAndRepaymentsQuery, TError, TData>(
      ['getLoansAndRepayments', variables],
      fetcher<Types.GetLoansAndRepaymentsQuery, Types.GetLoansAndRepaymentsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetLoansAndRepaymentsDocument, variables),
      options
    );
export const GetAssetVaultDisbursementsAndRepaymentsDocument = `
    query getAssetVaultDisbursementsAndRepayments($portfolioAddress: String!) {
  assetsActions(where: {portfolio: $portfolioAddress}) {
    action
    principalAmount
    interestAmount
    outstandingAssets
    sender
    timestamp
    txHash
    assetReportId
    id
  }
}
    `;
export const useGetAssetVaultDisbursementsAndRepaymentsQuery = <
      TData = Types.GetAssetVaultDisbursementsAndRepaymentsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetAssetVaultDisbursementsAndRepaymentsQueryVariables,
      options?: UseQueryOptions<Types.GetAssetVaultDisbursementsAndRepaymentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetAssetVaultDisbursementsAndRepaymentsQuery, TError, TData>(
      ['getAssetVaultDisbursementsAndRepayments', variables],
      fetcher<Types.GetAssetVaultDisbursementsAndRepaymentsQuery, Types.GetAssetVaultDisbursementsAndRepaymentsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetAssetVaultDisbursementsAndRepaymentsDocument, variables),
      options
    );
export const GetPortfoliosNamesDocument = `
    query getPortfoliosNames($addresses: [ID!]!) {
  structuredPortfolios(where: {id_in: $addresses}) {
    id
    name
  }
}
    `;
export const useGetPortfoliosNamesQuery = <
      TData = Types.GetPortfoliosNamesQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetPortfoliosNamesQueryVariables,
      options?: UseQueryOptions<Types.GetPortfoliosNamesQuery, TError, TData>
    ) =>
    useQuery<Types.GetPortfoliosNamesQuery, TError, TData>(
      ['getPortfoliosNames', variables],
      fetcher<Types.GetPortfoliosNamesQuery, Types.GetPortfoliosNamesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetPortfoliosNamesDocument, variables),
      options
    );
export const GetStructuredPortfolioTokenDocument = `
    query getStructuredPortfolioToken($address: ID!) {
  structuredPortfolio(id: $address) {
    id
    asset {
      ...TokenFields
    }
  }
}
    ${TokenFieldsFragmentDoc}`;
export const useGetStructuredPortfolioTokenQuery = <
      TData = Types.GetStructuredPortfolioTokenQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: Types.GetStructuredPortfolioTokenQueryVariables,
      options?: UseQueryOptions<Types.GetStructuredPortfolioTokenQuery, TError, TData>
    ) =>
    useQuery<Types.GetStructuredPortfolioTokenQuery, TError, TData>(
      ['getStructuredPortfolioToken', variables],
      fetcher<Types.GetStructuredPortfolioTokenQuery, Types.GetStructuredPortfolioTokenQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetStructuredPortfolioTokenDocument, variables),
      options
    );