import { Subgraph, SUBGRAPH_URLS } from 'backend/constants'
import { getQueryDataSource, toGraphId } from 'utils'
import { useGetCarbonManagerQuery } from 'generated/graphql/structured-portfolios/client'
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react'
import { Database } from 'integrations/supabase/generated.types'
import { useQuery } from '@tanstack/react-query'
import { SupportedChainId } from 'constants/chain'

export const useIsManagerWhitelisted = (address: string | undefined, chainId: SupportedChainId | undefined) => {
  const user = useUser()
  const subgraphURL = chainId && SUBGRAPH_URLS[Subgraph.Structured][chainId]

  const { data, isLoading } = useGetCarbonManagerQuery(
    getQueryDataSource(chainId),
    { address: toGraphId(address) },
    { enabled: !!subgraphURL },
  )

  const { isLoading: backendWhitelistedLoading, isWhitelisted: backendWhitelisted } = useSupabaseGetWhitelisted(
    user?.email,
  )
  const isWhitelisted = data?.manager?.isWhitelisted || backendWhitelisted
  const wasWhitelisted = isWhitelisted !== undefined || backendWhitelisted
  const loading = isLoading || backendWhitelistedLoading
  return { isWhitelisted, wasWhitelisted, loading }
}

const useSupabaseGetWhitelisted = (email?: string) => {
  const supabase = useSupabaseClient<Database>()
  const { data, isLoading } = useQuery({
    queryKey: ['whitelist', email],
    queryFn: async () => {
      if (!email) return null
      const record = await supabase.from('Whitelist').select('email').eq('email', email).maybeSingle().throwOnError()
      return record.data
    },
  })

  return { isLoading, isWhitelisted: !!data?.email }
}
